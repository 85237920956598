<template>
    <section class="bg-white">

        <div class="container xs:d-none">
            <div class="row">

                <div class="xs:col-12 sm:col-6 md-up:col-3 py-4" style="line-height: 1">
                    <h4 class="mb-0 bold xs:d-inline">{{ $t('comments') }}</h4>
                    <span class="h3 font-light text-primary" :class="{'text-hide': comments === undefined}" style="transition: .3s">{{comments}}</span>
                </div>

                <div class="xs:col-12 sm:col-6 md-up:col-3 py-4" style="line-height: 1">
                    <h4 class="mb-0 bold xs:d-inline">{{ $t('views') }}</h4>
                    <span class="h3 font-light text-primary" :class="{'text-hide': views === undefined}" style="transition: .3s">{{views}}</span>
                </div>

                <div class="xs:col-12 sm:col-6 md-up:col-3 py-4" style="line-height: 1">
                    <h4 class="mb-0 bold xs:d-inline">{{ $t('likes') }}</h4>
                    <span class="h3 font-light text-primary" :class="{'text-hide': likes === undefined}" style="transition: .3s">{{likes}}</span>
                </div>

                <div class="xs:col-12 sm:col-6 md-up:col-3 py-4" style="line-height: 1">
                    <h4 class="mb-0 bold xs:d-inline">{{ $t('dislikes') }}</h4>
                    <span class="h3 font-light text-primary" :class="{'text-hide': dislikes === undefined}" style="transition: .3s">{{dislikes}}</span>
                </div>
            </div>
        </div>

        <div class="container py-3 sm-up:d-none">
            <nav class="nav flex-column">

                <span class="btn bg-white text-left w-100">
                    <i class="i-message-square float-left"></i>
                    <span>{{ $t('comments') }}  - {{comments}}</span>
                </span>

                <span class="btn bg-white text-left w-100">
                    <i class="i-eye float-left"></i>
                    <span>{{ $t('views') }} - {{views}}</span>
                </span>

                <span class="btn bg-white text-left w-100">
                    <i class="i-thumbs-up float-left"></i>
                    <span>{{ $t('likes') }} - {{likes}}</span>
                </span>

                <span class="btn bg-white text-left w-100">
                    <i class="i-thumbs-down float-left"></i>
                    <span>{{ $t('dislikes') }} - {{dislikes}}</span>
                </span>
            </nav>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ModelNewPage',
        props: {
            dislikes: {
                type: Number
            },
            likes: {
                type: Number
            },
            views: {
                type: Number
            },
            comments: {
                type: Number
            }
        }
    }
</script>
